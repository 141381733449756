import { Box, Typography, Stack, Button } from "@mui/material";


const Footer = () => {
  return (
    <Box borderTop={1}>
     <Typography fontFamily='karma' textAlign='center' py={1}>@2024 Harrison Otieno || Automate ventures. All rights reserved.</Typography>
    </Box>
  )
}

export default Footer
