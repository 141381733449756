import { Box, Typography, Stack, Button } from "@mui/material";


const Experience = () => {
  return (
    <Box sx={{textAlign: 'center', justifyContent: 'center', pt: 30}}>
      <Typography variant='h4' fontFamily='karma'>Pause! it is in my Resume but will add it here soon for you</Typography></Box>
  )
}

export default Experience
