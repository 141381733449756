import { Box, Typography, Stack, Button } from "@mui/material";


const Blog = () => {
  return (
    <Box sx={{textAlign: 'center', justifyContent: 'center', pt: 30}}>
      <Typography variant='h4' fontFamily='karma'>Coming Soon</Typography></Box>
  )
}

export default Blog
